import React from 'react'
import "./NotFound404.css"
import { NavLink } from 'react-router-dom';

const NotFound404 = () => {
  return (
    <div className='notfound-wrapper'>
        <div className='notfound-container'>
            <h1>
                Beklager!
            </h1>

            <h2>
                Vi fant ikke siden du leter etter
            </h2>
            <p className='notfound-tidbit'>Kanskje den finnes der ute et sted...</p>

            <p className='bold marked-text'>
                Mulige grunner
            </p>
                <li className='notfound-list'>
                    Adressen er feil
                </li>
                <li className='notfound-list'>
                    Lenken fungerer ikke eller er utdatert
                </li>
        </div>
        <NavLink to="/" className="notfound-button">Hjem</NavLink>
    </div>
  )
}

export default NotFound404
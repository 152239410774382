// src/pages/NotFound.js
import React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import NotFound404 from '../components/NotFound404';

const NotFound = () => {
  return (
      <div className="App">
        <Head />
        <CustomCursor />
        <main className='Primary'>
        <Header />
        <NotFound404 />
        <Footer />
        </main>
      </div>

  );
};

export default NotFound;

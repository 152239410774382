import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import DallasTekLogoL from '../assets/dallastek-logo-l';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(() => {
    return JSON.parse(localStorage.getItem('isScrolled')) || false;
  });
  const [isHidden, setIsHidden] = useState(() => {
    return JSON.parse(localStorage.getItem('isHidden')) || false;
  });
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false); // Track if the mobile menu is open

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > 50) {
        setIsScrolled(true);
        localStorage.setItem('isScrolled', true);
      } else {
        setIsScrolled(false);
        localStorage.setItem('isScrolled', false);
      }

      if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 100) {
        setIsHidden(true);
        localStorage.setItem('isHidden', true);
      } else if (currentScrollPosition < lastScrollPosition || currentScrollPosition <= 100) {
        setIsHidden(false);
        localStorage.setItem('isHidden', false);
      }

      setLastScrollPosition(currentScrollPosition);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setMenuOpen(false); // Close the mobile menu if resizing back to desktop
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [lastScrollPosition]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''} ${isHidden ? 'hide-header' : 'show-header'}`}>
      <div className="header-left">
        <NavLink to="/" className="title-container-header">
          <div className='header-logo-container'>
            <span className="title-main"><DallasTekLogoL /></span>
          </div>
        </NavLink>
      </div>
      {isMobile ? (
        <div className="header-right mobile-menu">
          <button className="menu-icon" onClick={toggleMenu}>
            {menuOpen ? <IoMdClose size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      ) : (
        <div className="header-middle">
          <NavLink to="/tjenester" className={({ isActive }) => isActive ? "h-mob active-link" : "h-mob"}>
            Tjenester
          </NavLink>
          <NavLink to="/losninger" className={({ isActive }) => isActive ? "h-mob active-link" : "h-mob"}>
            Løsninger
          </NavLink>
          <NavLink to="/om" className={({ isActive }) => isActive ? "about-btn active-link" : "about-btn"}>
            Om
          </NavLink>
        </div>
      )}
      {!isMobile && (
        <div className="header-right">
          <NavLink to="/kontakt" className={({ isActive }) => isActive ? "active-link" : ""}>
            Kontakt
          </NavLink>
        </div>
      )}

      {/* Mobile Menu */}
      <nav className={`mobile-nav ${menuOpen ? 'open' : 'closed'}`}>
        <NavLink
          to="/tjenester"
          onClick={toggleMenu}
          className={({ isActive }) => isActive ? "mobile-nav-link active-link" : "mobile-nav-link"}
        >
          Tjenester -&gt;
        </NavLink>
        <NavLink
          to="/losninger"
          onClick={toggleMenu}
          className={({ isActive }) => isActive ? "mobile-nav-link active-link" : "mobile-nav-link"}
        >
          Løsninger -&gt;
        </NavLink>
        <NavLink
          to="/om"
          onClick={toggleMenu}
          className={({ isActive }) => isActive ? "mobile-nav-link h-mob active-link" : "mobile-nav-link h-mob"}
        >
          Om -&gt;
        </NavLink>
        <NavLink
          to="/kontakt"
          onClick={toggleMenu}
          className={({ isActive }) => isActive ? "mobile-nav-link-contact active-link" : "mobile-nav-link-contact"}
        >
          Kontakt -&gt;
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;

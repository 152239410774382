// src/pages/Home.js
import React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Customers from './home/Customers';
import Services from './home/Services';
import Solutions from './home/Solutions';
import Skills from './home/Skills';
import ContactDallas from './home/ContactDallas';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import Divider from '../components/Divider';
import ColorApplier from '../components/ColorApplier';
import { ColorProvider } from '../context/ColorContext';
import HomeWelcome from './home/HomeWelcome';
import CubeContainer from './home/CubeContainer';

const Home = () => {
  return (
    <ColorProvider>
      <ColorApplier />
      <div className="App">
        <Head />
        <CustomCursor />
        <main className='Primary'>
        <Header />
          <HomeWelcome />
          <CubeContainer />
          <Customers />
          <Services />
          <Solutions />
          <div className="Gradient">
            <Skills />
            <Divider />
            <ContactDallas />
          </div>
          <Footer />
        </main>
      </div>
    </ColorProvider>
  );
};

export default Home;

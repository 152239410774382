// src/components/ContactDallas.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ContactDallas.css';

const ContactDallas = () => {
  const location = useLocation();

  const content = {
    '/': {
      heading: 'Fremtiden venter',
      paragraph: 'Har du et prosjekt?',
    },
    '/tjenester': {
      heading: 'Klar for neste steg?',
      paragraph: 'Snakk med oss i dag',
    },
    '/losninger': {
      heading: 'Veien fremover',
      paragraph: 'Hør hvordan vi kan hjelpe deg',
    },
  };

  const currentContent = content[location.pathname] || content['/'];

  return (
    <div className="contact-dallas">
      <h2>{currentContent.heading}</h2>
      <div className="contact-info">
        <p className="contact-text">{currentContent.paragraph}</p>
        <Link to="/Kontakt" className="contact">Kontakt oss -&gt;</Link>
      </div>
    </div>
  );
};

export default ContactDallas;

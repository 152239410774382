import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './TransitionOverlay.css';

const TransitionOverlay = () => {
  const [isAnimating, setIsAnimating] = useState(true); // Start covering the screen
  const [transitionComplete, setTransitionComplete] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Reset state on location change
    setIsAnimating(true); // Start slide-in on location change
    setTransitionComplete(false);

    const timer = setTimeout(() => {
      setIsAnimating(false); // Slide out after transition completes
      setTransitionComplete(true);
    }, 1000); // Duration of the slide-in animation

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    // Update body class based on transition completion
    if (transitionComplete) {
      document.body.classList.add('transition-complete');
    } else {
      document.body.classList.remove('transition-complete');
    }
  }, [transitionComplete]);

  return (
    <div className={`transition-overlay ${isAnimating ? 'slide-in' : 'slide-out'}`}></div>
  );
};

export default TransitionOverlay;

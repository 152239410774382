import { OnlanLogo, BrorvikskutoLogo, NorengrosLogo } from "../context/Logos";

export const customers = [
  {
    name: "Brørvikskuto",
    logo: <BrorvikskutoLogo />,
    link: "http://www.brorvikskuto.no/",
    className: "brorvikskuto-logo"
  },
  {
    name: "Norengros HX",
    logo: <NorengrosLogo />,
    link: "https://www.norengros.no/landing/hx",
    className: "norengros-logo",
  },
  {
    name: "",
    logo: <OnlanLogo />,
    link: "https://www.onlan.no/",
    className: "onlan-logo",
  },

];

import React, { useEffect, useRef, useState } from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import Divider from '../components/Divider';
import '../App.css'; // Assuming you have other global styles
import ServiceWelcome from './service/ServiceWelcome';
import ServiceDesign from './service/ServiceDesign';
import ServiceDivider from './service/ServiceDivider';
import ReverseServiceDivider from './service/ReverseServiceDivider';
import ServiceDevelopment from './service/ServiceDevelopment';
import ServiceShowcase from './service/ServiceShowcase';
import ServiceStrategy from './service/ServiceStrategy';
import ServiceConsulting from './service/ServiceConsulting';
import ServiceDiv from './service/ServiceDiv';
import ContactDallas from './home/ContactDallas';

const Services = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const serviceDesignRef = useRef(null);
  const serviceDevelopmentRef = useRef(null);
  const serviceConsultingRef = useRef(null);

  useEffect(() => {
    // Apply fade-in after a short delay
    setTimeout(() => {
      setFadeIn(true);
    }, 100); // Adjust this delay as needed

    // Hash navigation logic
    const scrollToSection = () => {
      switch (window.location.hash) {
        case '#service1':
          serviceDesignRef.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case '#service2':
          serviceDevelopmentRef.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case '#service4':
          serviceConsultingRef.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          break;
      }
    };

    scrollToSection();
    window.addEventListener('hashchange', scrollToSection);

    return () => {
      window.removeEventListener('hashchange', scrollToSection);
    };
  }, []);

  return (
    <div className={`App fade-in ${fadeIn ? 'visible' : ''}`}>
      <Head />
      <CustomCursor />
      <main className='BlackText'>
        <div className='Primary'>
          <Header />
          <ServiceWelcome />
          <ServiceShowcase />
          <ServiceDivider />
        </div>
        <ServiceStrategy />
        <div ref={serviceDesignRef}>
          <ServiceDesign />
        </div>
        <div ref={serviceDevelopmentRef}>
          <ServiceDevelopment />
        </div>
        <div ref={serviceConsultingRef}>
          <ServiceConsulting />
        </div>
        <ServiceDiv />
        <Divider />
        <ContactDallas />
      </main>
      <div className='Primary'>
        <Footer />
      </div>
    </div>
  );
};

export default Services;

// src/pages/Serices.js
import React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import Divider from '../components/Divider';
import '../App.css'; // Assuming you have other global styles
import ContactForm from './contact/ContactForm';


const Contact = () => {
  return (
      <div className="App">
        <Head />
        <CustomCursor />
        <main>
          <div className='Primary'>
          <Header />
          <ContactForm />
          <Footer />
          </div>
        </main>
      </div>
  );
};

export default Contact;

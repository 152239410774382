// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hjem from './pages/Hjem';
import Om from './pages/Om';
import Kontakt from './pages/Kontakt';
import Løsninger from './pages/Losninger';
import Tjenester from './pages/Tjenester';
import TransitionOverlay from './components/TransitionOverlay';
import StartOverlay from './components/StartOverlay';
import ScrollToTop from './components/ScrollToTop';
import './mobile.css'
import Head from './components/Head';
import NotFound from './pages/NotFound';

const App = () => {

  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <Router>
      <Head />
      <StartOverlay />
      <TransitionOverlay isAnimating={isAnimating} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Hjem setIsAnimating={setIsAnimating} />} />
        <Route path="/om" element={<Om setIsAnimating={setIsAnimating} />} />
        <Route path="/kontakt" element={<Kontakt setIsAnimating={setIsAnimating} />} />
        <Route path="/losninger" element={<Løsninger setIsAnimating={setIsAnimating} />} />
        <Route path="/tjenester" element={<Tjenester setIsAnimating={setIsAnimating} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

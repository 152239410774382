// src/utils/updateFavicon.js
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import DallasTekLogoLF from '../assets/dallastek-logo-l-favicon';

const updateFavicon = () => {
  const svgMarkup = ReactDOMServer.renderToStaticMarkup(<DallasTekLogoLF />);
  const encodedSvg = encodeURIComponent(svgMarkup);
  const faviconUrl = `data:image/svg+xml;charset=utf-8,${encodedSvg}`;

  let link = document.querySelector('link[rel="icon"]');
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = faviconUrl;
};

export default updateFavicon;

// src/pages/Serices.js
import React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import '../App.css';
import ProjectsWelcome from './projects/ProjectsWelcome';
import Solutions from './home/Solutions'
import ContactDallas from './home/ContactDallas';


const Solution = () => {
  return (
      <div className="App">
        <Head />
        <CustomCursor />
        <main>
          <div className='Primary'>
            <Header />
            <ProjectsWelcome />
            <Solutions />
          </div>
            <ContactDallas />
        </main>
        
        <div className='Primary'>
          <Footer />
        </div>
      </div>
  );
};

export default Solution;

// src/components/Head.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import updateFavicon from './updateFavicon';

const Head = () => {
  useEffect(() => {
    updateFavicon();
  }, []);

  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Dallas Tek - Din løsning for bedrift konsultering, utvikling, design, og digital tansformering i Bergen." />
      <meta name="keywords" content="Dallas Tek, Dallas tech, IT-løsninger, IT-konsultasjon, IT-tjenester, Webutvikling, Programvareutvikling, Apputvikling, Teknologirådgivning, IT-støtte, digitalisering, webutvikling, web-design, bedriftkonsultasjon, utvikling, design, digital transformasjon, IT-løsninger Bergen, Webutvikling Bergen, IT-konsulent Bergen, Programvareutvikling Norge, Digitalisering Bergen, IT-tjenester i nærheten, Webdesign Bergen, IT-rådgivning Norge, IT-støtte Bergen, Apputvikling Bergen, E-handelsløsninger, Nettside redesign, SEO-tjenester Bergen, Hosting-løsninger, UX/UI design, Tilpassede programvareløsninger, Skybaserte løsninger, Databaseadministrasjon, API-integrasjon, Teknologisk innovasjon, Bedriftsløsninger, Digitale verktøy, IT-sikkerhet, Datamigrering, Skalerbare løsninger, IT-prosjektledelse, Nettbutikk utvikling, Mobilapp utvikling, Kontinuerlig integrasjon og levering" />
      <link rel="stylesheet" href="style.css" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&italic@300;400;700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap" rel="stylesheet" />
      <title>Dallas Tek - Løsningen for din bedrift</title>

      <script src="script.js"></script>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "Dallas Tek",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Bergen",
              "addressRegion": "Vestland",
              "postalCode": "5003",
              "addressCountry": "NO"
            },
            "telephone": "+47-900-68-775",
            "url": "https://www.dallastek.no",
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "60.39299",
              "longitude": "5.32415"
            },
            "sameAs": [
              "https://www.linkedin.com/company/dallas-tek-l%C3%B8sninger/"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default Head;


import React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCursor from '../components/CustomCursor';
import Divider from '../components/Divider';
import '../App.css'; // Assuming you have other global styles
import AboutWelcome from './about/AboutWelcome';
import AboutLocation from './about/AboutLocation';
import AboutFounder from './about/AboutFounder';


const About = () => {
  return (
      <div className="App">
        <Head />
        <CustomCursor />
        <main>
          <div className='Primary'>
            <Header />
            <AboutWelcome/>
          </div>
            <AboutLocation />
          <div className='Primary'>
            <Footer />
          </div>
        </main>
      </div>
  );
};

export default About;
